<template>
  <v-dialog v-model="visible" max-width="600px">
    <v-card rounded="md" class="pb-4">
      <v-toolbar :color="themeColor" dark flat>
        <v-toolbar-title class="font-weight-bold">
          {{ this.$t("lang.sms.dialog.title") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <div class="text-body-1 pl-3 pr-3 pt-3" v-html="$t('lang.sms.dialog.content')"></div>
      <div :class="`btn-box d-flex ${xs ? 'flex-column pl-2 pr-2' : ''} justify-center mt-4`">
        <v-btn
          color="#6AC251"
          depressed
          rounded
          large
          dark
          class="text-transform-none"
          @click="handleAgree"
          :min-width="`${xs ? '' : '35%'}`"
        >
          <v-icon dark>mdi-check</v-icon>
          {{ $t("lang.sms.dialog.agree") }}
        </v-btn>
        <v-btn
          color="#E74B4B"
          depressed
          rounded
          dark
          large
          @click="close"
          class="text-transform-none"
          :min-width="`${xs ? '' : '35%'}`"
          :class="xs ? 'mt-2' : 'ml-4'"
        >
          <v-icon dark>mdi-close</v-icon>
          {{ $t("lang.sms.dialog.dontAgree") }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { themeColor } from "@/common/const";
export default {
  data() {
    return {
      themeColor,
      visible: false,
    };
  },
  computed: {
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    handleAgree() {
      this.$emit("agree");
      this.close();
    },
  },
};
</script>
