<template>
  <div :class="xs ? 'send-small-sms' : 'send-sms'">
    <div v-if="!statusPage">
      <div :class="`trace top ${xs ? 'small' : ''} triangle`">
        <div class="text-center">
          <p :class="`${xs ? 'text-h4' : 'text-h2'} mb-5 font-weight-bold`">
            {{ $t("lang.home.gpsTrace") }}
          </p>
          <p :class="`${xs ? 'subtitle-1' : 'text-h5'} font-weight-bold`">
            {{ $t("lang.home.gpsTraceSubTitle") }}
          </p>
        </div>
      </div>
      <div :class="`form  ${xs ? 'small' : 'card-box'} mt-10 pb-4`">
        <div class="form-items">
          <div class="label text-body-1 mb-3 font-weight-medium">
            {{ $t("lang.home.emailLabel") }}
          </div>
          <v-text-field
            :placeholder="$t('lang.home.emailPlaceholder')"
            filled
            rounded
            background-color="#F7F8F9"
            v-model="email"
            :rules="[rules.email]"
          ></v-text-field>
        </div>
        <div class="form-items mb-6">
          <div class="label text-body-1 mb-3 font-weight-medium">
            {{ $t("lang.home.phoneNumberLabel") }}
          </div>
          <country-number v-model="phoneNumber" ref="myCountryNumber"></country-number>
        </div>
        <div class="form-items">
          <div class="label message-label mb-3">
            <div class="text-body-1 font-weight-medium">{{ $t("lang.home.message") }}</div>
            <div :class="`select ${xs ? 'small' : ''}`">
              <v-select
                dense
                filled
                rounded
                background-color="#4E74F6"
                dark
                :items="templateArr"
                item-text="title"
                item-value="templateId"
                disable-lookup
                hide-details
                :menu-props="{
                  rounded: formRouned,
                  bottom: true,
                  offsetY: true,
                  nudgeTop: '-10px',
                }"
                :placeholder="$t('lang.home.smsTemplate')"
                v-model="selectTempVal"
                @change="handleMessageChange"
              ></v-select>
            </div>
          </div>
          <v-textarea
            filled
            rounded
            dense
            solo
            flat
            readonly
            v-model="textareaVal"
            border-radius="10px"
            background-color="#F7F8F9"
          ></v-textarea>
        </div>
        <div class="form-items">
          <v-btn
            depressed
            color="#6AC251"
            dark
            large
            rounded
            @click="handleSendMessage"
            class="text-transform-none"
            :min-width="xs ? '116px' : '300px'"
          >
            {{ $t("lang.home.sendToPhone") }}
          </v-btn>
        </div>
      </div>
      <div :class="`tips ${xs ? 'small' : ''}`">
        <div :class="`${xs ? '' : 'card-box'} work`">
          <div class="mb-5">
            <div class="text-h6 mb-4">{{ this.$t("lang.sms.tips.workTitle") }}</div>
            <p class="text-body-1 mb-4">
              {{ this.$t("lang.sms.tips.workSubTitle") }}
            </p>
            <p class="text-body-1 mb-1">
              {{ this.$t("lang.sms.tips.workPayAttention.first") }}
            </p>
            <p class="text-body-1 mb-1">
              {{ this.$t("lang.sms.tips.workPayAttention.two") }}
            </p>
            <p class="text-body-1 mb-1">
              {{ this.$t("lang.sms.tips.workPayAttention.three") }}
            </p>
          </div>
          <div>
            <div class="text-h6 mb-4">{{ this.$t("lang.sms.tips.Instructions") }}</div>
            <div>
              <p class="text-body-1 mb-1">
                {{ this.$t("lang.sms.tips.InstructionsPayAttention.first") }}
              </p>
              <p class="text-body-1 mb-1">
                {{ this.$t("lang.sms.tips.InstructionsPayAttention.two") }}
              </p>
              <p class="text-body-1 mb-1">
                {{ this.$t("lang.sms.tips.InstructionsPayAttention.three") }}
              </p>
              <p class="text-body-1 mb-1">
                {{ this.$t("lang.sms.tips.InstructionsPayAttention.four") }}
              </p>
              <p class="text-body-1 mb-1">
                {{ this.$t("lang.sms.tips.InstructionsPayAttention.five") }}
              </p>
              <p class="text-body-1 mb-1">
                {{ this.$t("lang.sms.tips.InstructionsPayAttention.six") }}
              </p>
              <p class="text-body-1 mb-1">
                {{ this.$t("lang.sms.tips.InstructionsPayAttention.seven") }}
              </p>
            </div>
            <p class="mt-4 text-body-1">{{ this.$t("lang.sms.tips.warn.one") }}</p>
            <p class="mt-4 text-body-1">{{ this.$t("lang.sms.tips.warn.two") }}</p>
            <p class="mt-4 text-body-1">{{ this.$t("lang.sms.tips.warn.three") }}</p>
          </div>
        </div>
        <div :class="`${xs ? '' : 'card-box'} caution`">
          <div :class="`card-box__title ${xs ? 'small' : ''} text-h5`">
            {{ this.$t("lang.sms.tips.Caution") }}
          </div>
          <p class="mt-2 mb-3 text-body-1">{{ this.$t("lang.sms.tips.Caution.title") }}</p>
          <div class="list">
            <p class="text-body-1 mb-1">{{ this.$t("lang.sms.tips.Caution.payattention.one") }}</p>
            <p class="text-body-1 mb-1">{{ this.$t("lang.sms.tips.Caution.payattention.two") }}</p>
            <p class="text-body-1 mb-1">
              {{ this.$t("lang.sms.tips.Caution.payattention.three") }}
            </p>
            <p class="text-body-1 mb-1">{{ this.$t("lang.sms.tips.Caution.payattention.four") }}</p>
            <p class="text-body-1 mb-1">{{ this.$t("lang.sms.tips.Caution.payattention.five") }}</p>
            <p class="text-body-1 mb-1">{{ this.$t("lang.sms.tips.Caution.end") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <result-page
        :loading="sendTrackSmsLoading"
        :result="!sendTrackSmsLoading ? $t('lang.sms.result') : ''"
      ></result-page>
    </div>
    <agree-dialog ref="agreeDialog" @agree="handleAgreeSend"></agree-dialog>
  </div>
</template>
<script>
import CountryNumber from "@/components/countryNumber/country";
import { mapLoad } from "@/common/vue-plugin/httpService";
import { selectTemplate, sendTrackSms } from "@/service/sms";
import { formRouned } from "@/common/const";
import resultPage from "@/components/resultPage";
import { mapState, mapActions, mapMutations } from "vuex";
import AgreeDialog from "./AgreeDialog";
export default {
  components: { CountryNumber, AgreeDialog, resultPage },
  data() {
    return {
      textareaVal: "",
      formRouned,
      selectTempVal: "",
      email: "",
      templateArr: [],
      phoneNumber: "",
      templateVal: this.$t("lang.home.smsTemplate"),
      statusPage: false,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  created() {
    const countryInfo = this.$route.query;
    if (!this.countryArr || !this.countryArr.length) this.getCountry();
    if ("phoneNumber" in countryInfo) this.phoneNumber = countryInfo.phoneNumber;
    if ("email" in countryInfo) this.email = countryInfo.email;
    // 获取模板信息
    this.getTemplateInfo();
  },
  mounted() {
    window.gtag &&
      window.gtag("event", "pay_success", {
        event_category: "pay",
        event_label: "支付成功",
        event_value: 10,
      });


    window.gtag &&
    window.gtag('event', 'conversion', {
      'send_to': 'AW-323231674/MZyeCO7wv_sCELq_kJoB',
      'transaction_id': ''
    });
  },
  watch: {
    countryArr: {
      handler(nv) {
        this.$nextTick(() => {
          if (nv && nv.length && "countryId" in this.$route.query) {
            const index = nv.findIndex((i) => String(i.id) === String(this.$route.query.countryId));
            this.$refs.myCountryNumber.changeSelectedItem(index);
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState(["countryArr"]),
    ...mapLoad(["sendTrackSmsLoading"]),
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    ...mapActions(["getCountry"]),
    ...mapMutations(["mergeState"]),
    handleSendMessage() {
      const emailAddress = this.email;
      const result = this.$refs.myCountryNumber.getData();
      if (!emailAddress) {
        this.mergeState({
          snackbar: {
            show: true,
            msg: this.$t("lang.home.help.requried", [this.$t("lang.home.emailLabel")]),
            type: "error",
          },
        });
        return;
      }
      if (!result.phoneNumber) {
        this.mergeState({
          snackbar: {
            show: true,
            msg: this.$t("lang.home.help.requried", [this.$t("lang.home.phoneNumberLabel")]),
            type: "error",
          },
        });
        return;
      }
      this.$refs.agreeDialog.open();
    },
    async handleAgreeSend() {
      const result = this.$refs.myCountryNumber.getData();
      const templateId = this.selectTempVal;
      const trackOrderId = this.$route.query.trackOrderId;
      const emailAddress = this.email;
      this.statusPage = true;
      const { code } = await sendTrackSms({ templateId, trackOrderId, emailAddress, ...result });
      if (Number(code)) this.statusPage = false;
    },
    async getTemplateInfo() {
      // 语言测试先写死 en-US
      // const { data } = await selectTemplate({ languageCode: this.$getLang() });
      const { data } = await selectTemplate({ languageCode: "en-US" });
      if (!data || !data.list || !data.list.length) return;
      this.templateArr = data.list.map((i) => ({
        ...i,
        title: this.$t(i.title),
        content: this.$t(i.content),
      }));
    },
    handleMessageChange() {
      const content = this.templateArr.find((i) => i.templateId === this.selectTempVal).content;
      this.textareaVal = content
        ? `${this.$t(content)} \n ${location.origin}/#/find/${this.$route.query.serialId}`
        : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.send-sms {
  background: #f7f7f7;
  min-height: 100%;
}
.send-small-sms {
  background: #fff;
  min-height: 100%;
}
.form.small,
.tips.small {
  padding: 0 16px;
}
.message-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .t {
    display: flex;
    align-items: center;
  }
  .select {
    width: 350px;
    &.small {
      width: 200px;
    }
  }
}
.tips {
  display: flex;
  width: 70%;
  margin: 20px auto;
  .work {
    flex: 1;
    width: 100%;
  }
  &.small {
    width: 90%;
    flex-direction: column;
    .caution {
      flex: 1;
      width: 100%;
      margin-left: 0;
      margin-top: 12px;
    }
  }
  .caution {
    width: 400px;
    margin-left: 20px;
    position: relative;
    padding-top: 70px;
    overflow: hidden;
    .card-box__title {
      &.small {
        background: #6c5be7;
      }
      background: #414143;
      color: #fff;
      top: 0;
      height: 60px;
      line-height: 60px;
      left: 0;
      right: 0;
      padding: 0 20px;
      position: absolute;
      vertical-align: middle;
      &::before {
        content: "";
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        background: url("~@/assets/warn.png") no-repeat center center;
        background-size: contain;
        margin: 0 8px;
      }
    }
    .list p {
      &::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        margin: 0 4px;
        border-radius: 8px;
        background: #414143;
      }
    }
  }
}
</style>
