import { httpService } from "../common/vue-plugin/httpService";
import { root } from "@/common/config";
export const selectTemplate = (data) =>
  httpService(
    {
      url: `${root}/sms/template/selectTemplateByLanguage`,
      method: "get",
    },
    data,
  );

export const sendTrackSms = (data) =>
  httpService(
    {
      url: `${root}/order/sendTrackSms`,
      method: "post",
      isNeedLoading: true,
      storeKey: "sendTrackSmsLoading",
    },
    data,
  );
